// have inputs expand up to 500px in width, to keep elements after the input still in the visual focus.
// e.g. max length indicator, attached information at the end, ...
.text-input
    width: 100%
    max-width: 500px
    
.full-width
    width: 100%
    p-inputnumber
        width: 100% !important

.input-w100
    &.p-inputnumber
        width: 100%

gc-info > .gc-info-container 
    > gc-button.close-button .p-button
        width: 2rem
        height: 2rem
        padding: 0
        display: grid

.gc-button-text
    color: var(--gc-text-color) !important
    &:hover
        background: rgba(var(--gc-text-color-rgb), 0.04) !important
    &:active
        background: rgba(var(--gc-text-color-rgb), 0.16) !important

.contact
    &.p-inputgroup
        .p-inputtext
            border-radius: 0 3px 3px 0
        .p-inputgroup-addon 
            &:first-of-type 
                justify-content: left
                width: 220px
                min-width: 220px
                cursor: pointer
.input-group-transparent
    .p-inputgroup
        .p-inputgroup-addon
            background: rgba(var(--gc-color-medium-rgb), 0.05)

.surcharge-time-span
    &.p-inputgroup
        gc-datepicker
            &.w-100px
                width: 100px !important

.username
    &.p-inputgroup
        .p-inputgroup-addon
            min-width: unset !important
            color: var(--gc-color-medium-contrast)

.right-05
    right: 0.5rem

.hover-icon
    gc-icon
        display: none
    &:hover
        gc-icon
            display: flex

p-splitter
    .p-component
        .p-splitter-panel
            overflow: hidden
            min-height: 48px
            min-width: 48px

$iconSize: 42px
.menu-splitter
    .p-splitter-panel
        overflow: hidden
        min-height: calc($iconSize - 1px) !important // Adjust for border
        min-width: calc($iconSize - 1px) !important // Adjust for border


.hide-dialog-header
    .p-dialog-header
        display: none  !important

.comparison
    .p-dialog .p-dialog-content
        padding: 0

.gc-overview-panel
    .p-panel-header
        flex-direction: row-reverse
    &.between
        .p-panel-header
            justify-content: space-between


.space-between-header-panel
    .p-panel-header
        flex-direction: row
        justify-content: space-between

.triangle
    .pi
        background-color: orange
        padding: 0.3rem
        color: #fff
        font-size: 2rem
        border-radius: 10px

.overview
    > * 
        display: block
        margin-top: 1.5rem

.chip-green
    .p-chip
        background: var(--gc-color-success)
        color: var( --gc-color-secondary-contrast)

.chip-red
    .p-chip
        background: var(--gc-color-danger)
        color: var(--gc-color-primary-contrast)

.chip-yellow
    .p-chip
        background: var(--gc-color-warning)
        color: var(--gc-color-warning-contrast)

.chip-cyan
    .p-chip
        background: var(--gc-color-info)
        color: var(--gc-color-info-contrast)

.chip-gray
    .p-chip
        background: var(--gc-color-secondary)
        color: var(--gc-color-secondary-contrast)

.chip-black
    .p-chip
        background: var(--gc-text-color)
        color: var(--gc-text-color-inverse)

.chip-blue
    .p-chip
        background: var(--gc-color-primary)
        color: var(--gc-color-primary-contrast)

.filter-blue
    background: var(--gc-color-primary)
    color: var(--gc-color-primary-contrast)
    border-radius: 3px
    font-size: 1.25rem
    padding: 0.5rem

.gc-chip-text-small
    font-size: 0.875rem !important

.label-below
    p-checkbox
        display: flex
        flex-direction: column
        .p-checkbox-label
            padding-top: 0.5rem
            margin-left: 0

p-dataView.no-line
    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div
        border: none !important

//contract step open dropdown
.expand-button
    .pi
        padding: 0.1rem
        font-size: 1.1rem !important

gc-left-sidebar
    .p-splitter
        border: none
        border-radius: 0
        background: var(--gc-background-color)
    .sidebar-minimized
        .p-splitter-gutter
            display: none

.datev-table
    .p-paginator-rpp-options .p-dropdown-label
        padding: 0.5rem

.datev-button-connect
    background: #99e827
    color: #2c3335
    border: 0
    &:hover
        background: #7ad200
        color: #2c3335

.datev-footer-dialog-button
    margin: 0 !important

.p-inputtext
    padding: 0.75rem
    padding-bottom: 0.5rem

// layout
.grid
    // margin: 0 !important
.grid-nogutter 
    display: flex
    flex-wrap: wrap
.flex-column, .flex-row
    display: flex
// TODO: remove once grid is fixed
.grid-fix
    margin-right: -0.5rem !important
    margin-left: -0.5rem !important
    margin-top: -0.5rem !important

.gc-button-block
    .p-button:not(.p-button-icon-only)
            display: flex
            flex-direction: column
            .p-button-icon-left
                margin-bottom: 1rem
                margin-right: 0

.gc-breadcrumb
    &.p-breadcrumb
        background: transparent
        border: 0 none
        border-radius: 0
        padding: 0 !important
        .p-breadcrumb-chevron:before
            content: "\276F"

.p-calendar  
    .p-component.p-datepicker, .p-component.p-datepicker:not(.p-datepicker-inline)
        border-color: var(--gc-border-color)

.p-carousel
    width: inherit
    height: 50vh
    &-content
        flex-grow: 1
    &-container, &-items-container
        height: 100%
    
.p-carousel-item
    min-height: 0
    > *
        height: 100%
        width: 100%
        display: flex
        align-items: stretch
        > *
            width: 100%
            .wrapper
                display: flex
                flex-direction: column
                .contract-card
                    height: 100% !important

.p-dropdown
    width: 100%

.dialog-modal
    padding: var(--wrapper-gap, 0.5rem) !important
    > *[role="dialog"] > *:not(router-outlet)
        display: grid
        height: 100%

.circle-icon-button .p-button
    width: 38px
    height: 38px
    display: grid
    place-items: center

.curved-box
    background-color: var(--gc-content-background)
    border-radius: 8px
    padding: 1rem

gc-left-sidebar, gc-general-sidebar
    p-splitter .p-splitter
        height: 100%
    p-tree .p-tree
        border: none
        .p-treenode-content
            flex-direction: row-reverse
        .p-treenode-label
            flex-grow: 1
            margin-left: 0.5rem
        .p-treenode-icon
            display: none
        p-treenode .p-treenode
            padding: 0 !important
            .p-treenode-content
                height: 49px
            &:has(.p-treenode-content.p-highlight)//, &:has(.p-treenode-children)
                background: var(--gc-content-background) !important
                border-radius: 8px
            .p-highlight
                font-weight: bold
    .p-splitter-vertical>.p-splitter-gutter>.p-splitter-gutter-handle
        height: 9px
        width: 42px
        background: var(--gc-color-secondary-tint)

    .nav
        &-entry
            display: flex
        &-icon
            width: 16px
            height: 16px
        &-label
            flex-grow: 1
            margin-left: 0.5rem
.gc-time-correction-inplace
    .p-inplace .p-inplace-display
        display: inline-block
        &:not(.p-disabled):hover
            background: none
            color: none

.gc-card-same-height
    .p-card
        height: 100%
    .gc-dashboard-card-body 
        height: 100%
        display: flex
        flex-direction: column
    .p-card-body
        display: flex
        flex-direction: column
        flex-grow: 1
    .p-card-content
        flex-grow: 1

.no-body-card
    .p-card-body
        display: none
    .p-card
        width: 100%

//this is for the scrollbar inside gc-card in time-corrections page from dashboard
.time-correction-page
    .gc-card-request
        .p-card-content
            padding: 13px 15px !important
            margin: -13px
            max-height: 150px
            overflow-y: auto
            

.p-buttonset gc-button
    .p-button
        height: 100%
        color: var(--gc-text-color)
        border-color: var(--gc-color-dark-tint)
        background: transparent !important
    &.p-highlight, &:not(.p-disabled):not(.p-highlight):hover, &.p-highlight:hover
        .p-button
            border-color: var(--gc-color-dark-tint) 
            background: var(--gc-color-dark-tint) !important
            color: var(--gc-background-color)
    

@media (prefers-color-scheme: dark)
    .p-buttonset gc-button
        &.p-highlight, &:not(.p-disabled):not(.p-highlight):hover, &.p-highlight:hover
            .p-button
                color: var(--gc-text-color-inverse)


.p-overlay
    .p-multiselect-panel 
        .p-multiselect-header
            background: var(--gc-content-background)
            border-bottom-color: var(--fc-border-color) !important
        .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover
            background: var(--gc-content-background)
        border: 1px solid var(--fc-border-color)
        border-radius: 3px
        margin-top: -2px
    box-shadow: var(--gc-box-shadow)

.user-top-bar.p-overlaypanel 
    .p-overlaypanel-content
        padding: 1rem !important

.attendance-overview
    .p-splitter
        border: none
        height: 100%

    .p-dataview.p-component.p-dataview-list, .p-dataview.p-component.p-dataview-grid
        display: grid
        grid-template-rows: min-content 1fr
        overflow: hidden
    .p-dataview .p-dataview-content
        overflow: auto

    .p-dataview-header:has(.header)
        background: var(--ion-background-color)
        border-bottom: solid var(--gc-border-color)
        border-width: 0 0 1px 0 !important
        .header
            justify-content: space-between

    .p-dataview .p-dataview-content
        background: var(--gc-content-background)
    .attendance
        background: var(--gc-background-color)

    .p-dataview.p-dataview-list .p-dataview-content > .p-grid > div
        border-top: 0

.p-selectbutton 
    > .p-button
        &:not(:first-child, :last-child)
            border-radius: 0
        &:first-child
            border-top-right-radius: 0
            border-bottom-right-radius: 0
        &:last-child
            border-top-left-radius: 0
            border-bottom-left-radius: 0

.menu-chip
    display: inline
    .p-chip
        background: var(--gc-color-success)
        color: var(--gc-color-success-contrast)
        font-size: 0.75rem
.time-correction-overview-table
    .p-datatable
        &.p-datatable-gridlines
            &:has(.p-datatable-thead)
                &:has(.p-datatable-tbody)
                    .p-datatable-tbody
                        tr
                            td
                                &:nth-child(2)
                                    border-width-right: initial
                                    border-right: 3px solid var(--gc-color-light-shade)
                                &:nth-child(3)
                                    border-width-left: initial
                                    border-left: 3px solid var(--gc-color-light-shade)
                                    
        .p-datatable-thead
            tr
                th
                    &:nth-child(2)
                        border-right: 3px solid var(--gc-color-light-shade)
                        border-left: none
                    &:nth-child(3)
                        border-left: 3px solid var(--gc-color-light-shade)
                    &:first-of-type
                        border: none
        .p-datatable-tbody
            tr
                td
                    &:nth-child(2)
                        border-right: 3px solid var(--gc-color-light-shade)
                    &:nth-child(3)
                        border-left: 3px solid var(--gc-color-light-shade)
                    &:first-of-type
                        border: none
        



.p-input-icon-left
    gc-input-text .p-inputtext
        padding-left: 2rem

.hours-per-week
    gc-inputnumber,p-inputnumber
        width: auto

gc-inputnumber 
    .p-inputnumber
        width: 100%

.p-dropdown.inplace
    background: transparent
    border: none
    .p-dropdown-label
        padding: 0
    .p-dropdown-trigger
        display: none
    &:not(.p-disabled).p-focus
        box-shadow: none
        border-color: none

// .p-button.p-button-text 
//     text-decoration: underline
.p-button.p-button-text-color, .p-buttonset.p-button-text > .p-button, .p-splitbutton.p-button-text > .p-button
    color: var(--ion-text-color)
    border: 1px solid var(--ion-text-color)
button:has(gc-icon)
    padding: 0.5rem

@media(prefers-color-scheme:dark)
    .p-toast 
        .p-toast-message.p-toast-message-success,
        .p-toast-message.p-toast-message-warn,
        .p-toast-message.p-toast-message-error,
        .p-toast-message.p-toast-message-info
            background: rgb(35 41 38 / 95%)

    p-chip .p-chip
        background: var(--gc-color-secondary-shade)
        color: var(--gc-text-color)

.p-toast .p-toast-message .p-toast-message-content .p-toast-detail
    white-space: pre-line

// Otherwise one pixel row is shown behind the table header when scrolling
.p-datatable-scrollable-table>.p-datatable-thead
    top: -1px !important

gc-inputswitch
    display: flex
    align-items: center
    label
        margin-left: 1rem


p-overlay, .p-overlay
    .p-dropdown
        &-panel
            background: var(--gc-background-color)
            .p-dropdown-items
                .p-dropdown-item
                    &:not(.p-highlight):not(.p-disabled):hover
                        background: var(--gc-color-light-shade)
                    &.p-highlight
                        color: var(--gc-text-color)
                        background: rgba(var(--gc-color-primary-rgb), 0.5)

.pin
    &.checked .p-togglebutton
        background: var(--gc-color-primary) 
        color: var(--gc-text-color-inverse) 
    .p-togglebutton
        color: var(--gc-text-color) 
        border-color: var(--gc-border-color) 
        background: var(--gc-color-warning)
        box-shadow: var(--gc-box-shadow)
        border-radius: 0 30px 0 0
        padding: 0.5rem
        width: 36px

.menu-splitter
    .p-splitter .p-splitter-gutter
        background: var(--gc-color-light-tint)
    .p-splitter-gutter-handle
        background: var(--gc-border-color) !important
        border-radius: 5px
    .p-splitter-panel
        min-height: 200px !important
p-panel.no-content
    .p-panel-header
        padding: 0.5rem 1.25rem
    .p-panel-content
        visibility: hidden
        
gc-card.absence
    .p-card
        background: var(--gc-content-background)
        border: 1px solid var(--gc-border-color)
        .p-card-body, .p-card-content, .p-card-footer
            padding: 0

.absence-card-button
    width: 1.5rem !important
    height: 1.5rem !important
    span
        font-size: 0.75rem !important

.person-search-table
    .p-datatable-loading-overlay
        background: transparent !important
    .p-paginator-current
        cursor: default
    .p-paginator-element.p-disabled
        opacity: 0.2
    &.p-datatable 
        .p-datatable-thead > tr > th
            padding: 0.5rem !important
        .p-datatable-tbody > tr > td
            padding: 0.5rem

.p-overlaypanel.full-height
    .p-overlaypanel-content
        height: 100%

.listbox-nopadding
    .p-overlaypanel-content
        padding: 0 !important

.person-search-overlay
    min-height: 350px
    height: 100%
    max-height: 45vh
    min-width: 500px
    width: 100%
    max-width: 80vw
    .p-overlaypanel-content
        padding: 1rem !important
        padding-bottom: 0 !important

.absence-tooltip
    background: var(--gc-background-color) !important
    color: var(--gc-text-color) !important
    border: 1px solid var(--gc-border-color)


gc-icon
    fa-icon
        // FA-ICON schluckt mouse-events und gibt die nicht weiter
        pointer-events: none
.credential-icon-button
    width: 34px !important
    button
        padding: 0.55rem !important
        color: var(--gc-text-color) !important

// will swallow pointer events in firefox, thus making clicking on buttons awkward
.p-ink
    pointer-events: none !important

p-chip.user-chip
    gc-user
        margin-left: -0.75rem !important
        height: 32px
    .p-chip 
        height: 32px
        display: inline-flex
        img
            margin-left: 0 !important
            margin-right: 0 !important

.bitButton
    box-shadow: var(--gc-box-shadow)
    border-radius: 4px
    .container
        display: flex
        align-items: stretch
    p-togglebutton
        .p-togglebutton
            &.p-button
                height: 100%
                min-width: 6rem
                &:not(.p-disabled)
                    &:not(.p-highlight)
                        background: var(--gc-color-light)
                        color: var(--gc-color-light-contrast)
                        border-color: var(--gc-color-medium)
                        &:hover
                            background: var(--gc-color-light-shade)
                            color: var(--gc-color-light-contrast)
                            border-color: var(--gc-color-medium)
                    &.p-highlight
                        background: var(--gc-color-success)
                        color: var(--gc-color-success-contrast)
                        border-color: var(--gc-color-success-shade)
                        &:hover
                            background: var(--gc-color-success-shade)
                            color: var(--gc-color-success-contrast)
                            border-color: var(--gc-color-success-shade)
                border-radius: 0
        
        &:first-of-type
            .p-togglebutton
                &.p-button
                    border-top-left-radius: 4px
                    border-bottom-left-radius: 4px
                    border-right: 0px
        &:last-of-type
            .p-togglebutton
                &.p-button
                    border-top-right-radius: 4px
                    border-bottom-right-radius: 4px
        &:only-of-type
            .p-togglebutton
                &.p-button  
                    border-radius: 4px
                    border-right: 1px solid var(--gc-text-color)


@media screen and ( max-width: 1100px )
    .org-chart-dialog
        max-width: 100vw !important
        width: 100vw !important
.org-chart-dialog
    max-width: 1100px !important
    width: 100% !important
    .p-dialog-content
        display: grid

.time-account-tooltip
    background: var(--gc-background-color) !important
    color: var(--gc-text-color) !important
    border: 1px solid var(--gc-border-color)

.group
    &-list
        ul
            display: flex
            flex-wrap: wrap
            max-width: 600px
            padding: 0.5rem !important
            gap: 0.5rem    
        li
            border-radius: 3px !important
            border: 1px solid var(--gc-border-color) !important

.unset-type-chip
    background: rgba(var(--gc-color-warning-rgb), 0.3) !important
    border: 1px solid var(--gc-border-color)

.zoom-button
    font-size: 2rem !important
    height: 40px
    padding: 0.5rem 1.5rem !important
    &:not(.zoom-on) 
        background: var(--gc-color-secondary) !important
        border-color: var(--gc-border-color) !important     
.eye-button
    font-size: 1.2rem !important
    height: 40px
    padding: 0.5rem 1.5rem !important
    gap: 0.5rem
    flex-grow: 1
    &:not(.zoom-on) 
        background: var(--gc-color-secondary) !important
        border-color: var(--gc-border-color) !important        
.search.searchbox
    gc-input-text
        input
            padding-left: 2rem

// TODO Workaround till disabled works again on buttons (and other elements)
// prevents click events and overlays a gray-ish cover on top of the element
.gc-disabled
    position: relative
    cursor: default
    pointer-events: none
    user-select: none
    opacity: 0.65
    > .p-component
        background: var(--gc-color-light-shade)
    &.default-label
        opacity: 1
        > .p-component
            opacity: 0.65

.departmentPanel
    .p-panel-header
        padding: 0rem !important
        cursor: pointer
        position: relative
        background: var(--gc-background-color) !important
        border-radius: 0 !important
        &:before
            content: ''
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            background: var(--departmentColor) !important
            opacity: 0.5
        .p-panel-title
            z-index: 1
    .p-panel-content
        padding: 0 !important
        border: 0 !important
    .p-panel-header-icon
        color: var(--gc-text-color) !important

.text-color-warning-contrast
    color: var(--gc-color-warning-contrast) !important


.p-listbox 
    .p-listbox-header
        background: var(--box-background) !important
        .p-listbox-filter-icon
            color: var(--gc-text-color) !important

    &:not(.p-disabled) .p-listbox-item:not(.p-highlight):not(.p-disabled):hover
        background: var(--gc-color-secondary) !important

.no-collapse, .no-collapse table
    border-collapse: separate !important

.footer-border
    tfoot > tr:first-child td
        border-top: 2px solid var(--gc-color-dark-tint) !important
.simple-tooltip
    background: var(--gc-background-color) !important
    color: var(--gc-text-color) !important
    border: 1px solid var(--gc-border-color)

gc-toggleButton
    .p-button-primary:not(.p-highlight)
        background: var(--gc-color-primary) !important

.template-tooltip
    max-width: 800px !important
    background: var(--gc-background-color) !important
    color: var(--gc-text-color) !important
    border: 1px solid var(--gc-border-color) !important

.p-inplace
    &.no-padding
        .p-inplace-display
            padding: 0 !important
    &.cursor-default
        .p-inplace-display
            cursor: default
    .p-inplace-display:focus
        box-shadow: none !important
        outline: none !important

gc-input-textarea.vertical
    textarea
        resize: vertical


.employee-button
    > *:first-child 
        .p-togglebutton, .p-buttonset .p-button:first-of-type
            border-top-left-radius: 8px !important
            border-bottom-left-radius: 8px !important
    > *:last-child 
        .p-togglebutton, .p-buttonset .p-button:last-of-type
            border-top-right-radius: 8px !important
            border-bottom-right-radius: 8px !important

    .p-buttonset .p-button, .p-togglebutton
        display: inline-flex
        padding: 0.1rem 0.25rem
        color: var(--gc-text-color) !important
        border: 1px solid var(--gc-border-color) 
        border-radius: 0 
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important
        opacity: 1 !important
        align-items: flex-end

    .p-togglebutton
        border-color: var(--gc-border-color) !important
        background: var(--box-background) !important
    .p-buttonset .p-button
        background: var(--gc-background-color) !important

    .p-togglebutton.p-highlight, .p-buttonset .p-button.p-highlight
        background: var(--gc-color-primary) !important
        color: var(--gc-text-color-inverse) !important
        
    .p-button:focus
        box-shadow: none
.forced-person
    .employee-button
        .p-togglebutton.p-highlight, .p-buttonset .p-button
            background: var(--gc-text-color) !important
            color: var(--gc-text-color-inverse) !important


gc-search
    .p-datatable-flex-scrollable>.p-datatable-wrapper
        border-bottom: 1px solid var(--gc-border-color)

.gc-carousel
    .p-carousel-item
        max-width: calc(100% / 3)
    @media screen and (max-width: 1180px)
        .p-carousel-item 
            max-width: calc(100% / 2)
    @media screen and (max-width: 980px)
        .p-carousel-item 
            max-width: 100%



.fields
    .p-togglebutton
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important
    .department-select
        .p-multiselect
            background: var(--box-background)
            border: 1px solid var(--gc-border-color)
            border-radius: 8px
            display: inline-flex
        .p-multiselect-label
            padding: 0.25rem 0.5rem
            width: 120px
            height: 28px
            text-overflow: ellipsis
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"
            display: flex
            align-items: center
        .p-multiselect-label.p-placeholder
            color: var(--gc-text-color)
        .p-inputwrapper-filled
            .p-multiselect-trigger
                color: var(--gc-text-color-inverse) !important
            .p-multiselect
                background: var(--gc-text-color) !important
                color: var(--gc-text-color-inverse) !important
            
gc-documentation-components, gc-documentation-styling
    .p-tabview
        height: 100%
        overflow: hidden
        display: grid
        grid-template-rows: auto 1fr
        .p-tabview-panels, .p-tabview-panel
            overflow: auto
            height: 100%
        .p-tabview-panel > * 
                width: calc(100% - 1rem)
                display: grid
                height: 100%
        .p-highlight
            .p-tabview-nav-link
                color: var(--ion-text-color-inverse) !important
                background: var(--ion-color-primary) !important
        .p-tabview-nav-link
            border-width: 0 !important
            border-bottom-width: 1px !important
            border-radius: 0 !important

    gc-scroll-container
        min-height: 150px

.p-overlaypanel.p-0
    .p-overlaypanel-content
        padding: 0

.hue-slider, .saturation-slider
    .p-slider.p-slider-horizontal
            height: 2.2rem
            background: transparent
            .p-slider-range
                background: transparent
            .p-slider-handle
                height: 2.2rem
                top: 0 !important
                width: 0.25rem
                border-radius: 0
                background: var(--gc-text-color)
                margin: 0 !important
                border: none

.p-multiselect .p-multiselect-label
    padding: 0.75rem
    padding-bottom: 0.5rem

// TODO: use as default style?
gc-selectbutton.analysis
    p-selectbutton
        .p-selectbutton
            width: fit-content !important


gc-selectbutton.vierkant
    width: fit-content !important
    &.stretch // @Sandra we should not be stretching elements anymore, this causes issues with the layout when things change (e.g. translation/language)
        width: 100% !important
    p-selectbutton
        .p-selectbutton
            display: grid
            grid-auto-flow: column
            grid-auto-columns: 1fr
            box-shadow: var(--gc-box-shadow)
            .p-button
                height: auto
                background: var(--gc-color-light)
                border-color: var(--gc-color-secondary)
                color: var(--gc-color-light-contrast)
                box-shadow: none
                    
                &.p-highlight
                    background: var(--gc-color-dark)
                    border-color: var(--gc-color-dark)
                    color: var(--gc-color-dark-contrast)
                &:not(.p-disabled)
                    &:not(.p-highlight)
                        &:hover
                            background: var(--gc-color-dark-tint)
                            border-color: var(--gc-color-dark)
                            color: var(--gc-color-dark-contrast)
                &.p-disabled
                    background: rgba(var(--gc-color-light-rgb), 0.3)
                    border-color: rgba(var(--gc-color-secondary-rgb), 0.6)
                    color: rgba(var(--gc-color-light-contrast-rgb), 0.5)

                .selected
                    display: none
                &.p-highlight
                    .selected
                        display: initial

$iconButtonSize: 44px

.icon-button
    height: $iconButtonSize
    width: $iconButtonSize
    display: flex
    place-content: center

.buttons.row-style
    grid-row: footer
    display: grid
    grid-template-columns: [trash-start] $iconButtonSize [trash-end back-start]1fr [back-end cancel-start] 1fr [cancel-end submit-start] 1fr [submit-end continue-start] 1fr [continue-end info-start] $iconButtonSize [info-end] 
    gap: 0.5rem
    
    border-top: 1px solid var(--gc-border-color)
    padding-top: var(--wrapper-gap, 0.5rem)
    .button-trash
        grid-column: trash
    .button-prevStep
        grid-column: back
    .button-cancel
        grid-column: cancel
    .button-submit
        grid-column: submit
    .button-nextStep
        grid-column: continue
    .button-info
        grid-column: info

.icon-button
    width: $iconButtonSize
    height: $iconButtonSize
    display: grid
p-dataViewLayoutOptions.icon-button
    width: unset
    .p-button
        height: $iconButtonSize

.countdown-timer
    *:first-child
        border-radius: 4px 0 0 4px
    *:last-child
        border-radius: 0 4px 4px 01


gc-times-editor
    .p-inputtext
        padding: 0.5rem 0.75rem

p-chip.p-0
    .p-chip
        padding: 0

gc-panel
    &.workflow, &.visibility
        .p-panel-header
            flex-direction: row-reverse
            cursor: default
            font-weight: bold
        .p-dropdown-label
            white-space: normal

v-workflow-inheritance, v-visibility-inheritance
    gc-container.table-row
        background: var(--ion-background-color)
        &:hover:not(:has(.table-row:hover)) > .containerComponent > .column
            background: var(--gc-color-light)
        .containerComponent
            display: contents

        .p-radiobutton.p-radiobutton-disabled
            opacity: 0
            &.p-radiobutton-checked, &.p-radiobutton-checked .p-disabled
                opacity: 1

.workflow-entity-list
    .p-chip
        height: 32px


$iconButtonSize: 44px

.buttons.row-style
    grid-row: footer
    display: grid
    grid-template-columns: [trash-start] $iconButtonSize [trash-end back-start]1fr [back-end cancel-start] 1fr [cancel-end submit-start] 1fr [submit-end continue-start] 1fr [continue-end info-start] $iconButtonSize [info-end] 
    gap: 0.5rem

    border-top: 1px solid var(--gc-border-color)
    padding-top: var(--wrapper-gap, 0.5rem)
    .button-trash
        grid-column: trash
    .button-prevStep
        grid-column: back
    .button-cancel
        grid-column: cancel
    .button-submit
        grid-column: submit
    .button-nextStep
        grid-column: continue
    .button-info
        grid-column: info


app-test-page
    .p-tabview-nav-container
        display: grid