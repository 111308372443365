// temporary stylesheet for draft and components till those are merged into the external packages 

/**
 * TODO: 
 * - unify text and dropdown height
 */

gc-input-text,
gc-autoComplete,
gc-dropdown,
gc-calendar,
gc-input-textarea,
gc-inputNumber,
gc-selectbutton,
gc-fullcalendar,
gc-password .p-password,
gc-multiselect,
p-multiselect .p-multiselect.p-component,
input
    width: 100% !important
.p-inputgroup
    gc-inputNumber
        input
            width: 100% !important
            display: inline
.gc-full-button
    width: 100% !important

gc-colorPicker
    .p-colorpicker, .p-colorpicker-preview 
        height: 100%

gc-datepicker
    &.font-weight-bold
        input
            font-weight: 700
    &.color-grey
        input
            color: var(--gc-color-secondary-shade)
    &.small
        width: 120px
        .p-inputtext
            text-align: center
    &.smaller
        .p-datepicker table td
            width: 2rem
            height: 2rem
            span
                width: 1.625rem
                height: 1.625rem
            
    .p-datepicker table td
        padding: 0
        > span.p-highlight
            border-radius: 50%
            width: 2rem
            height: 2rem
            border: 2px solid #bbdefb

.gc-selectbutton-100
    .p-button
        width: 33.33%
        height: 150px

// TODO: replace this with a similar style as prime buttons
//overwriting secondary style from prime on gc-button
.black-button-style
    button
        color: var(--gc-text-color) !important
        span
            color: var(--gc-text-color) !important
// TODO: build some templates for layouting
.w-100
    width: 100% !important
.h-100
    height: 100% !important
.w-100px
    width: 100px !important
.w-200px
    width: 200px
.w-300px
    width: 300px

// TODO: proper error message container
// .error-msg
//     color: var(--gc-color-danger)
//     text-align: left
//     margin-top: 0.5rem