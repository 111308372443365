gc-toggleButton.shift-default-toggle
    .p-togglebutton
        width: 100%
        max-width: 400px
        background: var(--gc-color-primary) !important
        &:hover
            background: var(--gc-color-primary-shade) !important
        span
            white-space: nowrap

gc-selectButton
    &.no-radius
        .p-selectbutton .p-button
            border-radius: 0

    &.shift-mode-select
        .p-buttonset .p-button
            flex: 1
            padding: 0
        .p-selectbutton
            height: 30px !important
    &.alt-style, &.shift-mode-select
        .p-selectbutton
            display: flex
            height: 48px
            .p-button
                border-color: var(--fc-gray)
                color: var(--gc-text-color)
                background: transparent
                &.p-highlight, &:not(.p-disabled):not(.p-highlight):hover, &.p-highlight:hover
                    border-color: var(--fc-gray)
                    background: var(--fc-gray)
                    color: var(--gc-text-color-inverse)
        .p-buttonset .p-button
            font-size: 1rem
            display: flex
            justify-content: center
    &.button-style
        .p-selectbutton
            display: flex
            .p-button
                display: flex
                flex-grow: 1
                font-weight: bold
                background: var(--gc-color-light)
                border-color: var(--gc-color-secondary)
                color: var(--gc-color-light-contrast)
                height: 60px
                box-shadow: var(--gc-box-shadow)
                &:first-of-type
                    margin-right: 0.5rem
                    border-radius: 4px                    
                &:not(:last-child)
                    border-right: 1px solid var(--gc-color-secondary)
                    border-radius: 4px
                &:last-of-type
                    margin-left: 0.5rem
                    border-radius: 4px
                    
                &.p-highlight
                    background: var(--gc-color-medium)
                    border-color: var(--gc-color-medium)
                    color: var(--gc-color-medium-contrast)

@media (prefers-color-scheme: dark)
    gc-selectButton.shift-mode-select
        .p-selectbutton
            .p-button
                color: var(--gc-text-color)
                &.p-highlight, &:not(.p-disabled):not(.p-highlight):hover, &.p-highlight:hover
                    color: var(--gc-text-color)
gc-fullcalendar
    .p-buttonset gc-button 
        span
            white-space: nowrap
        &:first-child
            button
                border-top-left-radius: 3px !important
                border-bottom-left-radius: 3px !important
        &:last-child
            button
                border-top-right-radius: 3px !important
                border-bottom-right-radius: 3px !important
            
        // min-width: 100px
.fc-event-list-default
    &::before
        content: '⬤'
        color: var(--fc-event-border-color)
        margin-right: 0.25rem
    margin: 0 0.5rem
    align-items: baseline
    .time
        margin-right: 0.25rem
    .title
        font-weight: bold

.clickable
    cursor: pointer !important

.appointment-page
    gc-multiselect .p-multiselect
        border-radius: 3px
        background: transparent
        border-color: var(--fc-border-color)
        .p-multiselect-label.p-placeholder
            color: var(--gc-text-color)
    .shift-event
        z-index: 5 !important
    .openingTime-event
        opacity: 0.15 !important

.shift-plan-editor
    .fc-event-dragging:not(.fc-event-selected)
        // pointer-events: none
    gc-inputswitch
        label
            margin-left: 1rem    
    p-listbox
        .p-listbox .p-listbox-list .p-listbox-item
            padding: 0
    .button
         .p-togglebutton, .p-button
            height: 100%
            width: 100%
    .employee-selector
        .p-dropdown-label
            padding: 16px !important
    p-dropdown
        .p-dropdown
            width: 100%
    .fc-daygrid-event
        .shift-template
            position: absolute
            top: 0
            bottom: 0
            left: 0
            right: 0
            background: var(--fc-gray)
            color: var(--gc-text-color)
            border-radius: 3px
            border: 1px solid var(--gc-color-secondary-shade)
            height: 48px
            padding: 16px 10px
            box-shadow: var(--gc-box-shadow)
            border-radius: 3px
            &.default
                background: var(--gc-color-secondary)
    .fc-toolbar-title
        width: 350px
        text-align: center    
    .fc-theme-standard td, .fc-theme-standard th
        border-color: var(--gc-border-color) !important
    .fc-bg-event
        border: none !important
    .fc-col-header-cell 
        padding: 10px !important
    .fc.fc-theme-standard .fc-view-harness th:last-child
        border-left:  none !important
    .fc .fc-timegrid-axis-frame, .fc .fc-daygrid-day-frame
        // height: 84px !important
    
    .fc .fc-daygrid-week-number
        background: transparent
        font-weight: bold
    .fc-daygrid-day
        >.fc-highlight
            background: transparent !important
    .fc-daygrid-event 
        border: none !important
        border-radius: 3px
    .fc-toolbar-chunk 
        div
            display: flex
            align-items: center
            .fc-button
                background: transparent !important
                border: none !important
                color: var(--gc-text-color) !important
                margin: 1rem

.fc-event 
    .shift .employee
        position: absolute
        // TODO rework event templating
        left: -1px
        right: -1px
        top: -1px
        bottom: -1px
        background: var(--bg, var(--gc-text-color))
        color: var(--gc-text-color-inverse)
        display: flex
        justify-content: center
        align-items: center
        box-shadow: inset 0 0 2px 0px var(--gc-text-color-inverse)


.area-stepper
    gc-colorPicker
        .p-colorpicker, .p-colorpicker-preview 
            height: 100%


.event-template-stepper
    gc-colorPicker
        .p-colorpicker, .p-colorpicker-preview 
            height: 100%



gc-fullcalendar.absence
    .fc-timeline-lane-frame
        z-index: 1
        &:hover
            background: rgba(var(--gc-color-medium-rgb), 0.1)
    .fc-day-sat, .fc-day-sun, .fc-slot-sat, .fc-slot-sun
        background: var(--fc-weekend-highlight) !important
    .fc-day-highlight
        background: var(--fc-holiday-highlight) !important
        position: relative
        &.fc-slot div
            position: sticky
            top: 1rem
            color: #fff
            mix-blend-mode: screen
            writing-mode: vertical-lr
            text-transform: uppercase
            text-orientation: upright
            text-align: center
            font-weight: bold
            margin: auto auto
            overflow-wrap: initial
            overflow: hidden
    .fc-timeline-slot-cushion
        width: 100%
    full-calendar.fc.fc-theme-standard .fc-view-harness .fc-cell-shaded
        background: var(--gc-background-color)
    .resource .pin
        margin-right: 0
        width: 36px
        height: 36px
        display: flex
        justify-content: center
        align-items: center
        img
            max-width: 16px
            max-height: 16px
    th .fc-datagrid-cell-main
        font-weight: bold
        margin-left: 0.25rem

p-inplace.flex
    .p-inplace .p-inplace-content
        display: flex

.time-editor-event:not(.fc-bg-event)
    max-width: 80%

.absence-event
    border: 1px solid
    padding: 0.5rem
    margin: -2px
    &.absence-unapproved
        background: repeating-linear-gradient(  45deg,  #00000029,  #00000029 10px,  #00000000 10px,  #00000000 20px)
    &.absence-delete
        background: repeating-linear-gradient(45deg, rgb(165 15 15 / 80%), rgb(255 0 0 / 62%) 10px, rgba(0, 0, 0, 0) 10px, rgba(0, 0, 0, 0) 20px)

gc-fullcalendar
    .header-item
        > *
            grid-row: 1


.gc-year-overview
    .fc-event.absence-day
        opacity: 1 !important
    .fc-event.not-in-month
        background: #a6abb1 !important
        opacity: 0.75 !important
    .fc-event.background-event
        background: var(--gc-color-warning) !important
        opacity: 0.3 !important
    .fc-event.weekend
        background: #9b9b9b
        opacity: 0.3 !important


.year-overview
    .p-dialog
        min-height: 750px
        max-width: 1200px
    .p-dialog-maximized
        max-width: unset !important


app-calendar-appointments
    .openingTime-event
        background: #7c8ea7 !important // TODO: replace with variable
    gc-fullcalendar
        .header
            padding: 0 1rem
            margin-bottom: 0 !important
            border-bottom: 1px solid var(--gc-border-color)


    .employee-resource
        cursor: pointer
        &:hover
            text-decoration: underline
    .fc-timegrid-now-indicator-line
        pointer-events: none

    .fc .fc-bg-event.absence
        background: repeating-linear-gradient( -45deg, #222, #222 10px, #7d7d7d 10px, #7d7d7d 20px) !important
    .fc .holiday .fc-event-default
        border-color: var(--gc-color-warning) !important

    gc-selectbutton
        .p-button
            &:first-child
                border-top-left-radius: 4px !important
            &:last-child
                border-top-right-radius: 4px !important


.shiftplan-employee-list
    max-width: 180px
    overflow-wrap: anywhere


gc-fullcalendar
    .shift
        gc-time span
            text-wrap: pretty

.appointments
    .fc-timegrid-body, .fc-timegrid-body table, 
    .fc-daygrid-body, .fc-daygrid-body table,
    .fc-col-header 
        width: 100% !important