body
    font-style: barlow
    color: var(--gc-text-color)
    height: 100vh
    width: 100vw
    margin: 0
    padding: 0
    background-color: var(--gc-content-background)        

router-outlet, p-dialog
    // prevent angular "router-outlet" with interfering with the layout
    display: contents

.width-initial
    width: initial !important

.width-200
    width: 200px !important

.full-page
    display: grid
    grid-template-rows: 1fr
    height: 100%

// TODO: remove once updating of gc-button works
app-login
    p-button button
        width: 100% !important

.p-button-transparent
    background: transparent
    border-color: transparent
    color: var(--gc-text-color)

    &:enabled:hover 
        background: transparent
        color: var(--gc-color-dark)
        // TODO: maybe remove the border completely if it interferes with some designs
        border-color: var(--gc-border-color)

// input
//     &::placeholder 
//         color: rgba(var(--gc-text-color-rgb), 0.2)

// .pre
//     white-space: pre-line

// ::-webkit-scrollbar
//   width: 10px
// ::-webkit-scrollbar-track
//   background: var(--gc-color-light)
// ::-webkit-scrollbar-thumb
//   background: var(--gc-color-medium-shade)
//   border-radius: 5px
// ::-webkit-scrollbar-thumb:hover
//   background: var(--gc-color-medium)

// .gc-no-label
//     display: flex !important
//     align-items: flex-end !important

// .gc-align-center
//     display: flex !important
//     align-items: center !important

// .gc-required
//     label
//         &::after
//             content: " *"

// .gc-light-grey
//     background-color: var(--gc-color-light)
//     width: 100%

// .gc-info
//     background-color: #fcffd1

// .gc-light-blue
//     background-color: #00C5D222

// .infobox
//     background-color: #0000001F
//     padding: 0.25rem

// .gray-box-info
//     background: var(--box-background)
//     padding: 1rem

// //overwriting secondary style from prime on gc-button
// .black-button-style
//     button
//         color: var(--gc-text-color) !important
//         span
//             color: var(--gc-text-color) !important

// .placeholder-box
//     background-color: var(--gc-content-background)

// .badge
//     font-size: 0.8rem
//     background: var(--primary-color)
//     padding: 0.25rem 0.5rem
//     color: var(--primary-color-text)
//     border-radius: 5px



// h1, h2, h3, h4, h5, h6, span, p, .hyphenate
//     hyphens: auto

// h5
//     font-weight: bold

// .no-hyphenate
//     hyphens: none
// .no-label-center
//     margin-top: 17px
//     align-self: center

// //FIXME: 
// // Kill all info icons for now until we have a propper solution for this
// gc-icon[icon=circle-info]
//     visibility: hidden

// //Contract Templates Preview
// .custom-upload
//     .file-upload
//         >.flex, >.flex>.flex
//             width: 100% !important
//     .custom-file-upload
//         background: var(--gc-color-secondary) !important
//         width: 100% !important
//         border: 1px solid var(--gc-color-secondary)
//         color: #000

// //TODO: @Rene put this into angular-libs
// gc-multiselect
//     .p-multiselect.p-component
//         width: 100%

// .full-height
//     height: 100% 

// .custom-panel-padding 
//     .p-panel 
//         .p-panel-content
//             padding: 1rem

// .w-100
//     width: 100%
// .w-100px
//     width: 100px
// .w-200px
//     width: 200px

// // This breaks other panels
// // .p-overlaypanel   
// //     --overlayArrowLeft: 339px !important

// gc-colorPicker
//     .p-colorpicker, .p-colorpicker-preview 
//         height: 100%

// gc-datepicker
//     &.font-weight-bold
//         input
//             font-weight: 700
//     &.color-grey
//         input
//             color: var(--gc-color-secondary-shade)
//     &.small
//         width: 120px
//         .p-inputtext
//             text-align: center
//     &.smaller
//         .p-datepicker table td > span
//             width: 2rem
//             height: 2rem
            
//     .p-datepicker table td
//         padding: 0
//         > span.p-highlight
//             border-radius: 50%
//             width: 2rem
//             height: 2rem
//             border: 2px solid #bbdefb


// .absence-profile-entry
//     .p-panel-header
//         background: var(--gc-background-color) !important
//     border: var(--gc-border-color)
//     box-shadow: var(--gc-box-shadow)


// .draft-dialog-modal
//     .p-dialog-content
//         border-radius: 4px !important
//     border-radius: 4px !important
//     border: 1px solid var(--gc-border-color) !important


// .error-msg
//     color: var(--gc-color-danger)
//     text-align: left
//     margin-top: 0.5rem



// TODO: remove duplicate style
.summary-box
    border: 1px solid var(--gc-color-light-shade)
    padding: 0 1rem 1rem 1rem
    height: 100%

//TODO put it in right place
.section-box
    border: 1px solid rgba(var(--gc-color-medium-rgb), 0.3)
    padding: 1rem

gc-row
    &.grow-50
        & > *
            flex: 1 0 50%
    &.grow-1
        & > *
            flex-grow: 1
gc-column
    &.content-center
        & > *
            display: flex
            justify-content: center


.link-bold
    color: var(--gc-color-primary)
    text-decoration: underline
    font-weight: bold
    cursor: pointer

.flackbox-summary > gc-flackbox
    p-checkbox
        .p-checkbox
            &.p-checkbox-disabled
                .p-checkbox-box
                    opacity: 0.65
                    border: none
                    &.p-highlight
                        opacity: 1
                        background: transparent
                        border: none
                            
                .p-checkbox-label
                    &.p-disabled
                        opacity: 0.5
                        font-weight: 100
                .p-checkbox-label-active
                    &.p-disabled
                        opacity: 1




.flackbox-summary
    .col-12
        gc-flackbox
            p-checkbox
                .p-checkbox
                    &.p-checkbox-disabled
                        .p-checkbox-box
                            opacity: 0.65
                            border: none
                            &.p-highlight
                                opacity: 1
                                background: transparent
                                border: none
                            
                .p-checkbox-label
                    &.p-disabled
                        opacity: 0.5
                        font-weight: 100
                .p-checkbox-label-active
                    &.p-disabled
                        opacity: 1



// //TODO more elements when needed        
// .pristine-validate
//     .p-field-radiobutton
//         .ng-invalid
//             .p-radiobutton
//                 .p-radiobutton-box
//                     border: 2px solid var(--gc-color-danger)

//     &.ng-invalid
//         input
//             border-color: var(--gc-color-danger)
//             border-width: 4px
//             border-style: double
//             &:hover
//                 border-color: var(--gc-color-danger)

//         .p-selectbutton
//             .p-button
//                 border-color: var(--gc-color-danger)
//                 border-width: 4px
//                 border-style: double
//                 &:hover
//                     border-color: var(--gc-color-danger)
//         .p-dropdown
//             border: 4px double var(--gc-color-danger)
//             &:hover
//                 border-color: var(--gc-color-danger)

        
.curser-pointer
    cursor: pointer


// TODO: remove styles

.generic-stats-container
    height: 100%
    background-color: var(--gc-background-color)
    padding: 1rem

.border-03
    border: 1px solid rgba(var(--gc-color-medium-rgb), 0.3)

.border-top-03
    border-top: 1px solid rgba(var(--gc-color-medium-rgb), 0.3)