.color-primary
    color: var(--gc-color-primary)
.color-secondary
    color: var(--gc-color-secondary)
.color-warning
    color: var(--gc-color-warning)
.color-danger
    color: var(--gc-color-danger)
.color-success
    color: var(--gc-color-success)
.color-light
    color: var(--gc-color-light)
.color-medium
    color: var(--gc-color-medium)
.color-dark
    color: var(--gc-color-dark)
.color-text
    color: var(--gc-color-text)

.color-always-dark
    color: var(--gc-always-dark)

.background-primary
    background-color: var(--gc-color-primary)
.background-secondary
    background-color: var(--gc-color-secondary)
.background-warning
    background-color: var(--gc-color-warning)
.background-danger
    background-color: var(--gc-color-danger)
.background-success
    background-color: var(--gc-color-success)
.background-light
    background-color: var(--gc-color-light)
.background-light-tint
    background-color: var(--gc-color-light-tint)
.background-medium
    background-color: var(--gc-color-medium)
.background-dark
    background-color: var(--gc-color-dark)
.background-box
    background-color: var(--box-background)
.background-app-wrapper
    background-color: var(--gc-content-background)
.background-default
    background-color: var(--gc-background-color)

.border-color-primary
    border-color: var(--gc-color-primary)
.border-color-secondary
    border-color: var(--gc-color-secondary)
.border-color-warning
    border-color: var(--gc-color-warning)
.border-color-danger
    border-color: var(--gc-color-danger)
.border-color-success
    border-color: var(--gc-color-success)
.border-color-light
    border-color: var(--gc-color-light-shade)
.border-color-medium
    border-color: var(--gc-color-medium)
.border-color-dark
    border-color: var(--gc-color-dark)
.border-color-text
    border-color: var(--gc-text-color)