:root {
    --v-border-radius: 4px;
    --v-input-background-color: var(--ion-background-color);
    
    --gc-corner-radius: 3px;
    --gc-box-shadow-color: rgba(34, 36, 40, 0.25);
    --gc-box-shadow: 0 2px 8px var(--gc-box-shadow-color);
    --gc-card-focus-color: 126, 126, 126; // TODO: can we remove this? 
    --gc-error-background: rgb(255, 231, 230);
    --gc-error-border: rgb(255, 87, 87);
    --gc-always-dark: rgb(18, 18, 18); // TODO: same as `--gc-shadow-color`
    --box-background: #E9ECEF; // color used in many places 
    
    --gc-body-color-rgb: 240, 240, 240;
    --gc-body-color: rgb(var(--gc-body-color-rgb));
    
    --gc-background-color-rgb: 249, 249, 249;
    --gc-background-color: rgb(var(--gc-background-color-rgb));

    --gc-table-header-color: rgb(var(--gc-color-light-tint-rgb));
    --gc-table-border-color: var(--gc-border-color);
    --gc-table-striped-color: #f4f4f4;
    --gc-table-hover-color: rgba(var(--gc-color-medium-rgb), 0.1);
    
    --gc-color-primary-rgb: 0, 116, 173;
    --gc-color-primary: rgb(var(--gc-color-primary-rgb));
    --gc-color-primary-contrast: rgb(255, 255, 255);
    --gc-color-primary-shade: rgb(0, 102, 152);
    --gc-color-primary-tint: rgb(26, 130, 181);
    
    --gc-color-secondary-rgb: 172,172,172;
    --gc-color-secondary: rgb(var(--gc-color-secondary-rgb));
    --gc-color-secondary-contrast: rgb(35, 31, 32);
    --gc-color-secondary-shade: rgb(151, 151, 151);
    --gc-color-secondary-tint: rgb(180, 180, 180);
    
    --gc-color-info-rgb: 0,197,210;
    --gc-color-info: rgb(var(--gc-color-info-rgb));
    --gc-color-info-contrast: rgb(35, 31, 32);
    --gc-color-info-shade: rgb(0, 173, 185);
    --gc-color-info-tint: rgb(26, 203, 215);
    
    --gc-color-success-rgb: 76, 188, 134;
    --gc-color-success: rgb(var(--gc-color-success-rgb));
    --gc-color-success-contrast: rgb(35, 31, 32);
    --gc-color-success-shade: rgb(67, 165, 118);
    --gc-color-success-tint: rgb(94, 195, 146);
    
    --gc-color-warning-rgb: 251, 192, 45;
    --gc-color-warning: rgb(var(--gc-color-warning-rgb));
    --gc-color-warning-contrast: rgb(35, 31, 32);
    --gc-color-warning-shade: rgb(221, 169, 40);
    --gc-color-warning-tint: rgb(251, 198, 66);
    
    --gc-color-danger-rgb: 211, 47, 47;
    --gc-color-danger: rgb(var(--gc-color-danger-rgb));
    --gc-color-danger-contrast: rgb(255, 255, 255);
    --gc-color-danger-shade: rgb(186, 41, 41);
    --gc-color-danger-tint: rgb(215, 68, 68);
    
    --gc-color-dark-rgb: 34, 36, 40;
    --gc-color-dark: rgb(var(--gc-color-dark-rgb));
    --gc-color-dark-contrast: rgb(255, 255, 255);
    --gc-color-dark-shade: rgb(30, 32, 35);
    --gc-color-dark-tint: rgb(56, 58, 62);
    
    --gc-color-medium-rgb: 146, 148, 156;
    --gc-color-medium: rgb(var(--gc-color-medium-rgb));
    --gc-color-medium-contrast: rgb(255, 255, 255);
    --gc-color-medium-shade: rgb(128, 130, 137);
    --gc-color-medium-tint: rgb(157, 159, 166);
    
    --gc-color-light-rgb: 222, 223, 226;
    --gc-color-light: rgb(var(--gc-color-light-rgb));
    --gc-color-light-contrast-rgb: 0, 0, 0;
    --gc-color-light-contrast: rgb(var(--gc-color-light-contrast-rgb));
    --gc-color-light-shade: rgb(215, 216, 218);
    --gc-color-light-tint: rgb(234, 235, 237);
    --gc-color-light-tint-rgb: 234, 235, 237;
    
    --gc-border-color-rgb: 206, 212, 218;
    --gc-border-color: rgb(var(--gc-border-color-rgb));
    --gc-border-high-contrast-color-rgb: var(--gc-color-medium-rgb);
    --gc-border-high-contrast-color: rgb(var(--gc-border-high-contrast-color-rgb));
    
    --gc-content-background-rgb: 240, 240, 240;
    --gc-content-background: rgb(var(--gc-content-background-rgb));
    
    --gc-input-focus-color-rgb: 38, 143, 255;
    --gc-input-focus-color: rgba(var(--gc-input-focus-color-rgb), 0.5);
    
    --gc-input-focus-outline: 0.2rem solid rgba(var(--gc-input-focus-color));
    --gc-input-focus-shadow: 0 0 0 0.2rem rgba(var(--gc-input-focus-color));
    
    --gc-text-color-rgb: 18, 18, 18;
    --gc-text-color: rgb(var(--gc-text-color-rgb));
    
    --gc-text-color-inverse-rgb: 255, 255, 255;
    --gc-text-color-inverse: rgb(var(--gc-text-color-inverse-rgb));

    // these two colors should always stay the same, regardless of the color-scheme
    --gc-text-color-light: rgb(255, 255 , 255);
    --gc-text-color-dark: rgb(18, 18, 18);
    
    --gc-shadow-color-rgb: 18, 18, 18; // TODO: check if we can substitute this with another variable
    --gc-shadow-color: rgb(var(--gc-shadow-color-rgb));
}

@media (prefers-color-scheme: dark) {
    :root {
        --gc-box-shadow: 0 0 15px 2px var(--gc-box-shadow-color), 1px 0 var(--gc-border-color);
        --gc-error-background: rgb(98, 27, 24);

        --gc-body-color-rgb: 240, 240, 240;
        --gc-background-color-rgb: 18, 18, 18;

        --gc-table-header-color: var(--gc-color-light-shade);
        --gc-table-border-color: var(--gc-border-color);
        --gc-table-striped-color: #191919;
        --gc-table-hover-color: rgba(var(--gc-color-medium-rgb), 0.1);
        
        --gc-color-primary-rgb: 0, 171, 255;
        --gc-color-primary-contrast: rgb(0, 0, 0);
        --gc-color-primary-shade: rgb(0, 150, 224);
        --gc-color-primary-tint: rgb(26, 179, 255);
    
        --gc-color-secondary-rgb: 154, 154, 154;
        --gc-color-secondary: rgb(var(--gc-color-secondary-rgb));
        --gc-color-secondary-contrast: rgb(35, 31, 32);
        --gc-color-secondary-shade: rgb(111, 111, 111);
        --gc-color-secondary-tint: rgb(140, 140, 140);

        
        --gc-color-danger-rgb: 223, 104, 104;
        --gc-color-danger-contrast: rgb(0, 0, 0);
        --gc-color-danger-shade: rgb(196, 92, 92);
        --gc-color-danger-tint: rgb(226, 119, 119);
        
        --gc-color-dark-rgb: 244, 245, 248;
        --gc-color-dark-contrast: rgb(0, 0, 0);
        --gc-color-dark-shade: rgb(215, 216, 218);
        --gc-color-dark-tint: rgb(245, 246, 249);
        
        --gc-color-medium-rgb: 152, 154, 162;
        --gc-color-medium-contrast: rgb(0, 0, 0);
        --gc-color-medium-shade: rgb(134, 136, 143);
        --gc-color-medium-tint: rgb(162, 164, 171);
        
        --gc-color-light-rgb: 55, 58, 62;
        --gc-color-light-contrast-rgb: 255, 255, 255;
        --gc-color-light-shade: rgb(49, 51, 55);
        --gc-color-light-tint: rgb(76, 78, 81);  
        --gc-color-light-tint-rgb: 76, 78, 81;

        --gc-border-color-rgb: 87, 87, 87;
        
        --gc-content-background-rgb: 31, 31, 31;
        
        --gc-text-color-rgb: 255, 255, 255;
        --gc-text-color-inverse-rgb: 18, 18, 18;
        
        --gc-shadow-color-rgb: 0, 0, 0; // TODO: check if we can substitute this with another variable

        --box-background: #191a1d; //TODO check with sandra
    }
}

:root:has(body > .hmm) {
    --v-input-background-color: #fff;
    --v-border-radius: 4px;
    --v-border-radius-none: 0px;

    --gc-background-color-rgb: 7, 24, 44;
    --gc-background-color: rgb(var(--gc-background-color-rgb));
    --gc-content-background-rgb: 5, 15, 30;
    --gc-content-background: rgb(var(--gc-content-background-rgb));
    --gc-text-color-rgb: 255, 255 , 255;
    --gc-corner-radius: 0px;
    --gc-border-color-rgb: 152, 156, 160;

    --gc-color-primary-rgb: 14, 143, 202;
    --gc-color-primary: rgb(var(--gc-color-primary-rgb));
    // --gc-color-primary-contrast: rgb(255, 255, 255);
    // --gc-color-primary-shade: rgb(0, 102, 152);
    // --gc-color-primary-tint: rgb(26, 130, 181);
}

@media (prefers-color-scheme: dark) {
    :root:has(body > .hmm) {
        --gc-background-color-rgb: 7, 24, 44;
        --gc-background-color: rgb(var(--gc-background-color-rgb));
        --gc-content-background-rgb: 5, 15, 30;
        --gc-content-background: rgb(var(--gc-content-background-rgb));
        --gc-text-color-rgb: 255, 255 , 255;
        --gc-border-color-rgb: 152, 156, 160;

        --gc-color-primary-rgb: 14, 143, 202;;
        --gc-color-primary: rgb(var(--gc-color-primary-rgb));
        // --gc-color-primary-contrast: rgb(255, 255, 255);
        // --gc-color-primary-shade: rgb(0, 102, 152);
        // --gc-color-primary-tint: rgb(26, 130, 181);
    }
}