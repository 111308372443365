@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 100
    src: url('../assets/fonts/Roboto/Roboto-Thin.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 300
    src: url('../assets/fonts/Roboto/Roboto-Light.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 400
    src: url('../assets/fonts/Roboto/Roboto-Regular.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 500
    src: url('../assets/fonts/Roboto/Roboto-Medium.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 700
    src: url('../assets/fonts/Roboto/Roboto-Bold.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: normal
    font-weight: 900
    src: url('../assets/fonts/Roboto/Roboto-Black.ttf')

@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 100
    src: url('../assets/fonts/Roboto/Roboto-ThinItalic.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 300
    src: url('../assets/fonts/Roboto/Roboto-LightItalic.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 400
    src: url('../assets/fonts/Roboto/Roboto-Italic.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 500
    src: url('../assets/fonts/Roboto/Roboto-MediumItalic.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 700
    src: url('../assets/fonts/Roboto/Roboto-BoldItalic.ttf')
@font-face 
    font-family: 'Roboto'
    font-style: italic
    font-weight: 900
    src: url('../assets/fonts/Roboto/Roboto-BlackItalic.ttf')


:root 
    --_scale: 1.067
    --line-height: 1.6rem
    --p-size: 1rem
    --small-size: calc(var(--p-size) / var(--_scale) / var(--_scale))
    --h6-size: calc(var(--p-size) * var(--_scale))
    --h5-size: calc(var(--h6-size) * var(--_scale))
    --h4-size: calc(var(--h5-size) * var(--_scale))
    --h3-size: calc(var(--h4-size) * var(--_scale))
    --h2-size: calc(var(--h3-size) * var(--_scale))
    --h1-size: calc(var(--h2-size) * var(--_scale))
    --font-family: Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"

html, html .p-component, span, p
    font-family: var(--font-family)
    line-height: var(--line-height)
    text-rendering: optimizeLegibility

p
    line-height: calc(1ex / 0.32)
h1, h2, h3, h4, h5 ,h6
    margin: 0
    font-weight: bold

h1, h2, h3, h4, h5, h6, span, p, .hyphenate
    hyphens: auto

.no-hyphenate
    hyphens: none

.no-user-select
    user-select: none
    -webkit-user-select: none
    -moz-user-select: none

small, p.small
    font-size: var(--small-size)
html, p, label
    font-size: var(--p-size)
h1
    font-size: var(--h1-size)
h2
    font-size: var(--h2-size)
h3
    font-size: var(--h3-size)
h4
    font-size: var(--h4-size)
h5
    font-size: var(--h5-size)
h6
    font-size: var(--h6-size)

.text-vertical
    writing-mode: vertical-lr
    text-transform: uppercase
    text-orientation: upright
    text-align: center
    font-weight: bold
    margin: auto auto
    overflow-wrap: initial
    overflow: hidden