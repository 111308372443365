/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

// /* Core CSS required for Ionic components to work properly */
// @import "~@ionic/angular/css/core.css";

// // /* Basic CSS for apps built with Ionic */
// @import "~@ionic/angular/css/normalize.css";
// @import "~@ionic/angular/css/structure.css";
// @import "~@ionic/angular/css/typography.css";
// @import '~@ionic/angular/css/display.css';

// // /* Optional CSS utils that can be commented out */
// @import "~@ionic/angular/css/padding.css";
// @import "~@ionic/angular/css/float-elements.css";
// @import "~@ionic/angular/css/text-alignment.css";
// @import "~@ionic/angular/css/text-transformation.css";
// @import "~@ionic/angular/css/flex-utils.css";

/* Basic styling */
@import "./theme/structure.sass";

/* Custom Styles */
@import "./theme/global.sass";
@import "./theme/global-gc-style.sass";
@import "./theme/gc-global-color-classes.sass";
@import "./theme/gc-style-classes.sass";
@import "./theme/gc-custom-prime.sass";
@import "./theme/typography.sass";
@import "./theme/accessibility.sass";

// TODO: Delete once those styles have been merged into their respective packages - 2024-01-09
// We have no access to merge these styles, keeping them around as a workaround
@import "./theme/temp.style.sass";

/* Calendar */
@import "./theme//calendar/theme.calendar.sass";
@import "./theme/calendar/custom.style.sass";
@import "./theme/calendar/calendar-component.sass";

/*
*---------------------------------------------------------
*---------------------------------------------------------
* !!! Place global styles in global-gc-style.sass !!!
*---------------------------------------------------------
*---------------------------------------------------------
*/
