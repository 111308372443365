//CALENDAR-COMPONENT
$border-color: #f4f4f4
$border-color-darker: #8e99a4
full-calendar
    // table
    //     width: 100% !important
    //     height: 100% !important
    // .content 
    //     height: 100% !important
    // .fc-col-header, .fc-timegrid-body
    //     width: 100% !important
    //     height: 100% !important
    // .fc-scrollgrid-sync-table
    //     width: 100% !important
    //     height: 100% !important
    // .fc-daygrid-body
    //     width: 100% !important
    //     height: 100% !important
    // .fc-daygrid-body-balanced 
    //     width: 100% !important
    //     height: 100% !important
    .fc-header-toolbar
        display: none !important
    .fc-button.fc-button-primary
        background-color: var(--gc-color-secondary) !important
        border-color: var(--gc-color-secondary-shade) !important
    .fc-button.fc-button-primary.fc-button-active
        background-color: var(--gc-color-primary) !important
        border-color: var(--gc-color-primary-shade) !important
    .fc-timegrid-event-harness, .fc-event-dragging
        pointer-events: none
    .fc-event:not(.fc-event-dragging)
        pointer-events: all
    .fc-scrollgrid-sync-table
        border-bottom: none !important
    table[role="presentation"]
        border-bottom: 1px solid $border-color-darker !important
    table[role="grid"]
        border: 0px !important
        // border-left: 1px solid $border-color-darker !important
        // border-right: 1px solid $border-color-darker !important
    .fc-timegrid-axis-chunk 
        border-right: 1px solid $border-color-darker !important
    .fc-theme-standard td, .fc-theme-standard th
        border: 0px !important
        &[data-time$=":00:00"]
            border-top: 1px solid  $border-color !important
        &[data-time$=":30:00"]
            border-top: 1px dashed $border-color !important            
        &[data-time$=":15:00"]
            border-top: 1px dashed $border-color !important
        &.fc-timegrid-col
            border-right: 1px solid $border-color-darker !important
        &.fc-daygrid-day
            border: 1px solid $border-color-darker !important
    .fc-day-today
        background-color: rgba(var(--gc-color-primary-rgb),0.05) !important
    .fc.fc-theme-standard .fc-view-harness .fc-cell-shaded
        display: none
        // background-color:  $border-color-darker !important
    .fc.fc-theme-standard .fc-view-harness th
        border-left: 1px solid $border-color-darker !important
        border-right: 1px solid $border-color-darker !important
        background: transparent
        &.fc-day-today
            background: transparent !important
        &:first-child
            border-left: none !important
        &:last-child
            border-right: none !important
.fc .fc-scrollgrid-section-body td:nth-child(2) .fc-scroller-liquid-absolute
    overflow: overlay !important

.fc-event-title-container
    display: flex
    align-items: center
    justify-content: space-around     
.gc-event-contraint
    border: none !important
    background: var(--fc-event-border-color, #000) !important
    color: #fff
.gc-event-draggable
    border: 1px solid #333 !important
    background: #33333399 !important
    .fc-event-main
        color: #fff !important


.fc-v-event
    border-radius: 3px !important
    min-height: 25px    
.fc-event-dragging
    cursor: grabbing !important
.fc-event
    display: flex
    align-items: stretch
    background: trasnparent !important
    border: none !important
    &>.fc-event-main
        width: 100%
        color: var(--gc-text-color) !important

.fc-opening-time
    background: var(--fc-event-border-color)
    border-radius: 3px
    padding: 1rem
    font-size: 1rem
    height: 100%

gc-fullcalendar.copy-mode
    .fc-timegrid-slot
        cursor: copy


.fc-event
    &.fc-entry-primary
        background: var(--fc-event-border-color)
        border-radius: 3px
        font-size: 1rem
        *
            color: var(--gc-text-color-inverse) !important
    &.fc-entry-secondary
        background: var(--gc-color-secondary)
        border-radius: 3px
        font-size: 1rem
        *
            color: var(--gc-text-color) !important

    &.holiday
        cursor: default
.fc-timeline-header
    a
        text-decoration: initial


.shiftplan
    .fc-day
        &.fc-day-tue,
        &.fc-day-thu,
        &.fc-day-sat
            background: rgba(0,0,0, 0.0125)
        &:nth-child(1 of .fc-day-mon),
        &:nth-child(1 of .fc-day-tue),
        &:nth-child(1 of .fc-day-wen),
        &:nth-child(1 of .fc-day-thu),
        &:nth-child(1 of .fc-day-fri),
        &:nth-child(1 of .fc-day-sat),
        &:nth-child(1 of .fc-day-sun)
            border-left: 4px solid var(--gc-border-high-contrast-color) !important

        &:nth-last-child(1 of .fc-day-mon),
        &:nth-last-child(1 of .fc-day-tue),
        &:nth-last-child(1 of .fc-day-wen),
        &:nth-last-child(1 of .fc-day-thu),
        &:nth-last-child(1 of .fc-day-fri),
        &:nth-last-child(1 of .fc-day-sat),
        &:nth-last-child(1 of .fc-day-sun)
            border-right: 4px solid var(--gc-border-high-contrast-color) !important
