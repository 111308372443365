.gc-button-w-100
    &.p-button
        width: 100% !important
.gc-toggle-button-w-100
    &.p-togglebutton
        &.p-button
            width: 100% !important
.gc-button-mb-05
    &.p-button
        margin-bottom: 0.5rem
.gc-button-p-1
    &.p-button
        padding: 1rem
.gc-dropdown-w-100
    &.p-dropdown
        width: 100% !important
.gc-dropdown-w-200
    &.p-dropdown
        width: 200px !important
gc-dropdown
    &.w-200px
        width: 200px !important
.gc-button-w-250
    &.p-button
        width: 250px
        max-width: 250px
        min-width:250px

.paginator-style
    p-paginator
        .p-paginator 
            background: var(--gc-background-color)
            color: var(--gc-color-primary)
            border-width: 0
            padding: 0.75rem
            border-radius: 4px
        .p-paginator .p-paginator-first,
        .p-paginator .p-paginator-prev,
        .p-paginator .p-paginator-next,
        .p-paginator .p-paginator-last 
            background-color: transparent
            border: none
            color: var(--gc-color-dark)
            min-width: 2.357rem
            height: 2.357rem
            margin: 0 0 0 -1px
            transition: box-shadow 0.15s
            border-radius: 4px
        .p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
        .p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover 
            background: #e9ecef
            color: var(--gc-color-primary)
        .p-paginator .p-paginator-first 
            border: none
            border-top-left-radius: 4px
            border-bottom-left-radius: 4px
        
        .p-paginator .p-paginator-last 
            border: none
            border-top-right-radius: 4px
            border-bottom-right-radius: 4px
        .p-paginator .p-paginator-current 
            background-color: var(--gc-background-color)
            
            color: var(--gc-color-primary)
            min-width: 2.357rem
            height: 2.357rem
            margin: 0 0 0 -1px
            padding: 0 0.5rem
            border-radius: 4px
        .p-paginator .p-paginator-pages .p-paginator-page 
            background-color: var(--gc-background-color)
            color: var(--gc-color-secondary)
            border: none
            font-weight: 700
            min-width: 2.357rem
            height: 2.357rem
            margin: 0 0 0 -1px
            transition: box-shadow 0.15s
            border-radius: 4px
        .p-paginator .p-paginator-pages .p-paginator-page.p-highlight 
            background: var(--gc-color-primary)
            border-color: var(--gc-color-primary)
            color: var(--gc-color-primary-contrast)

        .p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover 
            background: var(--gc-color-light)
            color: var(--gc-color-primary)

.gc-button-w-220
    &.p-button
        width: 220px
        max-width: 220px
        min-width:220px

.gc-button-w-140
    &.p-button
        width: 140px
        max-width: 140px
        min-width:140px
.gc-button-w-auto
    &.p-button
        width: auto
        max-width: auto
        min-width: auto
gc-button
    &.width-auto
        width: auto !important

.gc-breadcrumb
    &.p-breadcrumb
        background: transparent
        border: 0 none
        border-radius: 0
        padding: 0.8rem 0
        margin-left: 1.5rem


.gc-progressbar
    &.p-progressbar
        background: transparent
        position: absolute
        width: 100%
        z-index: 9999

.gc-dialog
    &.p-dialog .p-dialog-content
        padding: 0
        user-select: none
.gc-contract-card-dropdown
    &.p-dropdown
        border-radius: 20px
        background: #4c4c4c
        .p-dropdown-label
            color: #f2f2f2
            font-size: 0.75rem
            padding: 0.2rem 0.7rem
        .p-dropdown-trigger
            color: #f2f2f2
            font-size: 0.7rem

//FIXME doesnt work
.gc-contract-card-button
    .p-buttonset .p-button
        width: 50%
        justify-content: center
        background-color: #f2f2f2
        color: #000000
        padding: 0.2rem
        font-size: 0.9rem
.gc-contract-card-selectbutton
    .p-selectbutton .p-button.p-highlight
        background: #000000
        border-color: #f2f2f2
        color: #ffffff
        border: 1px solid #000000

.works-on-holiday
    &.p-selectbutton
        .p-button
            border: 1px solid var(--ion-text-color)
            background: transparent
            color: var(--ion-text-color)
            padding: 0 0.5rem
            width: 3.5rem
    &-true
        &.p-selectbutton
            .p-button
                &.p-highlight
                    background: var(--ion-color-success)
                    color: var(--ion-color-success-contrast)
                    border: 1px solid var(--ion-text-color)
    &-false
        &.p-selectbutton
            .p-button
                &.p-highlight
                    background: var(--ion-color-danger)
                    color: var(--ion-color-danger-contrast)
                    border: 1px solid var(--ion-text-color)

.gc-selectbutton-100
    .p-button
        width: 33.33%
        height: 150px

.list-select-button
    &.p-buttonset .p-button
        margin: 0
        width: 100%
        border-radius: 0
        margin: 0.5rem
        padding: 1rem
        background: var(--gc-color-light-tint)
        border: 1px solid var(--gc-color-light-tint)
        color: var(--gc-color-light-contrast)
        box-shadow: 0px 2px 6px var(--gc-box-shadow-color)
        .highlighted-icon
            display: none
            position: absolute
            right: 0.5rem
            top: 0.5rem
            color: var(--gc-color-primary)
        &:not(.p-disabled)
            &:not(.p-highlight)
                &:hover
                    background: var(--gc-color-light)
                    border-color: var(--gc-color-light)
                    color: var(--gc-color-light-contrast)
            &.p-highlight
                &:hover
                    background: var(--gc-color-light)
                    border-color: var(--gc-color-light)
                    color: var(--gc-color-light-contrast)

        &.p-highlight
            background: var(--gc-color-light-shade)
            border-color: var(--gc-color-light-shade)
            color: var(--gc-color-light-contrast)
            .highlighted-icon
                display: block
            &:hover
                background: var(--gc-color-light)
                border-color: var(--gc-color-light)
                

.gc-dashboard-divider
    &.p-divider
        margin-top: 0 !important

.gc-dashboard-card
    &.p-card
        height: 100%
.gc-dashboard-card-body
    &.p-card
        .p-card-body
            padding: 1rem !important
        .p-card-title
            margin-bottom: 0 !important

.gc-dashboard-card-content
    &.p-card
        .p-card-content
            padding: 0 !important
.gc-dashboard-card-tag
    &.p-tag
        .p-tag-success
            background: #00C5D2 !important
            color: black !important

.gc-user-search-autocomplete
    &.p-autocomplete
        width: 100% !important
        align-items: stretch
        flex-direction: column
        .p-autocomplete-token
            border: 1px solid var(--gc-color-dark) !important
            border-radius: 25px !important
            background: transparent !important
            color: var(--gc-text-color) !important
    &.chip-placeholder
        color: #999
        font-weight: 300
        margin-left: 1rem
    &.suggestion
        display: flex
        &-name
            max-width: 100px
            flex: 1 0
        &-value
            max-width: 300px
        flex: 0
        &-placeholder
            flex: 1 1
    &.p-autocomplete-items
        padding-top: 0 !important

.gc-categories-tree-view
    &.p-component
        padding-left: 0 !important
        padding-right: 0 !important
    &.p-component
        border: none !important
    &.p-tree
        .p-tree-container
            padding-right: 0.143rem
            .p-treenode
                padding: 0.143rem 0 0.143rem 0.143rem
                .p-treenode-content:focus
                    box-shadow: none
                .p-treenode-content
                    &.p-treenode-selectable
                        .p-treenode-label
                            width: 100% !important
        .p-tree-container
            padding-right: 0.143rem

.gc-login-back-button
    &.p-button
      &.p-button-text
        color: #575757
        font-size: 0.9rem
        text-align: left
        width: 80%

.gc-select-page-card
    &.p-card
        .p-card-content
            display: flex
            justify-content: center
        .p-card-body
            padding: 1.5rem
            height: 170px

// .menu-splitter
//     &.p-splitter
//         border: none
//     &.p-splitter-vertical
//         max-height: 95vh

.gc-toggle-button-primary-outlined
    &.p-togglebutton
        &.p-button
            &.p-highlight
                background: var(--gc-background-color)
                border-color: var(--gc-color-primary)
                color: var(--gc-color-primary)
            &.p-highlight
                &:hover
                    background: var(--gc-background-color)
                    border-color: var(--gc-color-primary)
                    color: var(--gc-color-primary)
            &:not(.p-disabled)
                &:not(.p-highlight)
                    background: var(--gc-background-color)
                    border-color: var(--gc-color-primary)
                    color: var(--gc-color-primary)
                    &:hover
                        background: var(--gc-background-color)
                        border-color: var(--gc-color-primary)
                        color: var(--gc-color-primary)

.gc-button-outlined-bold
    &.p-button
        font-weight: bold
        width: 100% !important

.gc-inputnumber-w-100px
    &.p-inputnumber
        width: 100px
        height: 47px
.gc-inputnumber-width-100px
    &.p-inputnumber
        width: 100px
.gc-inputnumber-min-width-100px
    &.p-inputnumber
        min-width: 100px
gc-inputNumber
    &.w-100px
        width: 100px !important
    &.w-180px
        width: 180px !important
.gc-input-text-w-100px
    &.p-inputtext
        width: 100px
        height: 47px

.gc-inputnumber-w-100
    &.p-inputnumber
        width: 100%

.gc-inputnumber-w-100px-h-auto
    &.p-inputnumber
        width: 100px

gc-datepicker
    &.w-100px
        width: 100px !important

.gc-password
    &.p-password
        width: 100%
.gc-password-traffic-light
    &.p-password
        width: 100%
    &.p-password .p-password-panel
        height: auto !important
        min-width: auto
        position: relative
        top: 0 !important
        left: 0 !important
        padding: 2px
        display: flex
        justify-content: center
        flex-direction: column
        align-items: center

//use this class to achieve style that was to be found in app-component
.gc-datatable
    &.p-datatable 
        .p-sortable-column.p-highlight .p-sortable-column-icon,
        .p-sortable-column.p-highlight
            color: #000
        .p-sortable-column .p-sortable-column-badge
            color: #fff
            background: #6c757d

.gc-accordion-tab-m-1
    &.p-accordion p-accordiontab .p-accordion-tab
        margin-bottom: 14px !important

.gc-100
    > * > .p-component
        width: 100% !important

.gc-chip-draft
    &.p-chip
        height: 18px

.gc-chip-filter
    &.p-chip
        margin: 0.25rem
        .p-chip-text
            font-size: 0.85rem !important

.gc-chip-person-search-selected-none
    &.p-chip
        background: #00c421
        border: 1px solid #00c421
        .p-chip-text
            font-weight: bold
            color: #000
        .p-chip-icon
            font-weight: bold
            color: #000
    
.gc-chip-person-search
    &.p-chip
        background: var(--gc-color-light)
        border: 1px solid var(--gc-color-light)
        margin: 0.5rem
        .p-chip-text
            color: var(--gc-color-light-contrast)

.gc-button-contact
    &.p-button
        max-width: 250px
        min-width: 250px
    
.gc-container
    border: 1px solid var(--gc-border-color)
    border-radius: var(--gc-corner-radius)
    >.p-card
        box-shadow: none

.time-tracking
    p-selectbutton
        .p-selectbutton
            display: flex
            .p-button
                display: flex
                flex-grow: 1
                font-weight: bold
                background: var(--gc-color-light)
                border: 1px solid var(--gc-color-secondary)
                color: var(--gc-color-light-contrast)
                height: 60px
                box-shadow: var(--gc-box-shadow)
                &:first-of-type
                    margin-right: 0.5rem
                    border-radius: 4px                    
                &:not(:last-child)
                    border-radius: 4px
                &:last-of-type
                    margin-left: 0.5rem
                    border-radius: 4px
                    
                &.p-highlight
                    background: var(--gc-color-medium)
                    border-color: var(--gc-color-medium)
                    color: var(--gc-color-medium-contrast)
.active-select
    display: flex
    align-items: flex-end
    p-selectbutton
        .p-selectbutton
            width: 250px
            border: none
            .p-button
                width: 50%
                height: 48px
                border: none
                &:first-of-type
                    background: var(--gc-color-success)
                &:last-of-type
                    background: var(--gc-color-danger)

.time-tracking-limits
    p-selectbutton
        .p-selectbutton
            display: flex
            .p-button
                display: flex
                flex-grow: 1
                font-weight: bold
                background: var(--gc-color-light)
                border-color: var(--gc-color-secondary)
                color: var(--gc-color-light-contrast)
                height: 60px
                box-shadow: var(--gc-box-shadow)
                    
                &.p-highlight
                    background: rgba(var(--gc-color-medium-rgb), 0.5)
                    border-color: var(--gc-color-medium)
                    color: var(--gc-color-light-contrast)

                &:not(.p-disabled)
                    &:not(.p-highlight)
                        &:hover
                            background: rgba(var(--gc-color-medium-rgb), 0.2)
                            border-color: var(--gc-color-medium)
                            color: var(--gc-color-light-contrast)
.absence-analyses-settings
    p-selectbutton
        .p-selectbutton
            box-shadow: var(--gc-box-shadow)
            .p-button
                height: auto
                background: var(--gc-color-light)
                border-color: var(--gc-color-secondary)
                color: var(--gc-color-light-contrast)
                box-shadow: none
                    
                &.p-highlight
                    background: var(--gc-color-dark)
                    border-color: var(--gc-color-dark)
                    color: var(--gc-color-dark-contrast)
                &:not(.p-disabled)
                    &:not(.p-highlight)
                        &:hover
                            background: var(--gc-color-dark-tint)
                            border-color: var(--gc-color-dark)
                            color: var(--gc-color-dark-contrast)
                &.p-disabled
                    background: rgba(var(--gc-color-light-rgb), 0.3)
                    border-color: rgba(var(--gc-color-secondary-rgb), 0.6)
                    color: rgba(var(--gc-color-light-contrast-rgb), 0.5)

.person-shifts .p-button
    max-width: 250px
    display: inline-flex
    justify-content: center

// TODO: use style globally for all p-selectbuttons
.date-periods, .person-shifts
    p-selectbutton
        .p-selectbutton
            display: flex
            .p-button
                display: flex
                flex-grow: 1
                font-weight: bold
                background: var(--gc-color-dark-contrast)
                border-color: var(--gc-color-light-contrast)
                color: var(--gc-color-light-contrast)
                &.p-highlight
                    background: var(--gc-color-light-contrast)
                    color: var(--gc-color-dark-contrast)

.warning-icon
    .pi
        font-size: 2rem
        color: var(--gc-color-danger)

.generic-table-expanded
    &.p-datatable
        .p-datatable-wrapper
            table
                border-collapse: separate
            &.p-datatable-table
                width: 100%
              
        .p-datatable-thead
            tr
                th
                    background: var(--gc-table-header-color)
                    padding: 0.5rem
        .p-datatable-tfoot
            tr
                td
                    border-top-width: 2px
                    background: var(--gc-table-header-color)
        &.p-datatable-gridlines
            .p-datatable-thead
                tr
                    th
                        border-color: var(--gc-table-border-color)
            .p-datatable-tbody
                tr
                    &.clickable
                        cursor: pointer
                        &:hover
                            background-color: var(--gc-table-hover-color)
                    td
                        border-color: var(--gc-table-border-color)
                        padding: 0.5rem
        &.p-datatable-striped
            .p-datatable-tbody
                tr:nth-child(even)
                    background: var(--gc-table-striped-color)


        