// style for screen reader only, adds text to the DOM but prevents rendering. Only shows for clients not supporting CSS.
.visually-hidden
    clip-path: inset(100%)
    clip: rect(1px, 1px, 1px, 1px)
    height: 1px
    overflow: hidden
    position: absolute
    white-space: nowrap
    width: 1px

a:not(.default)
    color: var(--gc-color-primary)
    text-decoration: underline
    cursor: pointer

a:not(.default)
    &[href^="http"]:after, &[href^="tel:"]:before, &[href^="mailto:"]:before
        content: ''
        display: inline-block
        mask-size: auto 12px
        width: 12px
        height: 12px
        mask-repeat: no-repeat
        background-color: var(--gc-color-primary, #3880ff)
    &[href^="http"]:after
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M320 0c-17.7 0-32 14.3-32 32s14.3 32 32 32h82.7L201.4 265.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L448 109.3V192c0 17.7 14.3 32 32 32s32-14.3 32-32V32c0-17.7-14.3-32-32-32H320zM80 32C35.8 32 0 67.8 0 112V432c0 44.2 35.8 80 80 80H400c44.2 0 80-35.8 80-80V320c0-17.7-14.3-32-32-32s-32 14.3-32 32V432c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16H192c17.7 0 32-14.3 32-32s-14.3-32-32-32H80z'/%3E%3C/svg%3E")
    &[href^="tel:"]:before
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z'/%3E%3C/svg%3E")
    &[href^="mailto:"]:before
        mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='12' width='12' viewBox='0 0 512 512'%3E%3Cpath fill='%23ffffff' d='M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z'/%3E%3C/svg%3E")
        
        

full-calendar a, .p-menuitem a
    text-decoration: none  !important