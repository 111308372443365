// IMPORTANT - Only add styles which affect the whole page

// keep this for the future 
// html::after
//   content: 'CLEANUP'
//   position: absolute
//   right: 0.1rem
//   bottom: 0.1rem
//   font-weight: bold
//   font-size: xxx-large
//   color: rgba(var(--gc-text-color-rgb), 0.2)
//   user-select: none
//   pointer-events: none


// Scrollbar
::-webkit-scrollbar
  width: 10px
::-webkit-scrollbar-track
  background: transparent
::-webkit-scrollbar-thumb
  background: var(--gc-color-medium-shade)
  border-radius: 5px
::-webkit-scrollbar-thumb:hover
  background: var(--gc-color-medium)

@media(prefers-color-scheme: dark)
    ::-webkit-scrollbar-thumb
        background: var(--gc-color-dark-tint)


.gc-required
    > label:after, .p-field-radiobutton label:after
        content: ' *'
        user-select: none
        pointer-events: none
        color: var(--gc-color-danger)

//TODO more elements when needed        
// replace border with outline
.pristine-validate
    .p-field-radiobutton
        .ng-invalid
            .p-radiobutton
                .p-radiobutton-box
                    border-color: var(--gc-color-danger)
                    outline: solid 1px var(--gc-color-danger)
                    outline-offset: -4px

    &.ng-invalid
        input
            border-color: var(--gc-color-danger)
            outline: solid 1px var(--gc-color-danger)
            outline-offset: -4px
            &:hover
                border-color: var(--gc-color-danger)

        .p-selectbutton
            .p-button
                border-color: var(--gc-color-danger)
                outline: solid 1px var(--gc-color-danger)
                outline-offset: -4px
                &:hover
                    border-color: var(--gc-color-danger)
        .p-dropdown
            border-color: var(--gc-color-danger)
            outline: solid 1px var(--gc-color-danger)
            outline-offset: -4px
            &:hover
                border-color: var(--gc-color-danger)


.gc-shadow
    overflow: visible
    position: relative
    &:before
        content: ''
        box-shadow: var(--gc-box-shadow)
        z-index: -1
        position: absolute
        top: 0
        left: 0
        right: 0
        bottom: 0

// app root styling - do NOT change anything, if you not fully understand the consequences. Changes here WILL affect the whole application
// this is only a partial style view `src/app/app.component.sass` for the remaining styles
app-root
    > .app-container
        --wrapper-gap: 0.5rem
        > #content            
            > .wrapper 
                > *:not(router-outlet)
                    grid-row: content
                &.background
                    box-shadow: var(--gc-box-shadow)
                    background: var(--gc-background-color)
                    border-radius: 3px
                    border: solid var(--gc-border-color)
                    border-width: 1px 0 0 1px
                &.no-actionbar > *:not(router-outlet)
                    grid-row: actionbar/content
                &.pad-content > *:not(router-outlet)
                    padding: var(--wrapper-gap)

.gc-top-bar
    &.p-toolbar
        height: 45px
        padding: 0
        border-radius: 0
        border: none
        border-bottom: 1px solid var(--gc-border-color)
        box-shadow: var(--gc-box-shadow)
        background: var(--gc-background-color)
        .p-button
            color: var(--gc-text-color)
            background: transparent
            border: none
            padding: 0 1rem
            font-size: 1rem
            border-radius: 0
        .user-info
            margin-left: 1rem
            text-align: left
            .name
                font-size: 1rem
                font-weight: 600
        .user-image
            width: 1.5rem
            height: 1.5rem
            border-radius: 50%
            border: 1px solid darkgrey

gc-org-chart
    path.link
        stroke: #333
    @media(prefers-color-scheme: dark)
        path.link
            stroke: #fff

gc-drafthost
    .host-component-footer
        .footer-btn .p-button
            width: 100%

.buttons.row-style
    .p-button
        width: 100%