// Do not add or modify colors and variables here, instead modify `variables.scss`.
:root {
    --prime-input-focus: var(--gc-input-focus-color);
    
    --ion-background-color: var(--gc-background-color);
    --ion-text-color: var(--gc-text-color);
    --ion-text-color-inverse: var(--gc-text-color-inverse);
    --ion-border-color: var(--gc-border-color);
    
    /** primary **/
    --ion-color-primary: var(--gc-color-primary);
    --ion-color-primary-contrast: var(--gc-color-primary-contrast);
    --ion-color-primary-shade: var(--gc-color-primary-shade);
    --ion-color-primary-tint: var(--gc-color-primary-tint);
    
    /** secondary **/
    --ion-color-secondary: var(--gc-color-secondary);
    --ion-color-secondary-contrast: var(--gc-color-secondary-contrast);
    --ion-color-secondary-shade: var(--gc-color-secondary-shade);
    --ion-color-secondary-tint: var(--gc-color-secondary-tint);
    
    /** info **/
    --ion-color-info: var(--gc-color-info);
    --ion-color-info-contrast: var(--gc-color-info-contrast);
    --ion-color-info-shade: var(--gc-color-info-shade);
    --ion-color-info-tint: var(--gc-color-info-tint);
    
    /** success **/
    --ion-color-success: var(--gc-color-success);
    --ion-color-success-contrast: var(--gc-color-success-contrast);
    --ion-color-success-shade: var(--gc-color-success-shade);
    --ion-color-success-tint: var(--gc-color-success-tint);
    
    /** warning **/
    --ion-color-warning: var(--gc-color-warning);
    --ion-color-warning-contrast: var(--gc-color-warning-contrast);
    --ion-color-warning-shade: var(--gc-color-warning-shade);
    --ion-color-warning-tint: var(--gc-color-warning-tintg);
    
    /** danger **/
    --ion-color-danger: var(--gc-color-danger);
    --ion-color-danger-contrast: var(--gc-color-danger-contrast);
    --ion-color-danger-shade: var(--gc-color-danger-shade);
    --ion-color-danger-tint: var(--gc-color-danger-tint);
    
    /** dark **/
    --ion-color-dark: var(--gc-color-dark);
    --ion-color-dark-contrast: var(--gc-color-dark-contrast);
    --ion-color-dark-shade: var(--gc-color-dark-shade);
    --ion-color-dark-tint: var(--gc-color-dark-tint);
    
    /** medium **/
    --ion-color-medium: var(--gc-color-medium);
    --ion-color-medium-contrast: var(--gc-color-medium-contrast);
    --ion-color-medium-shade: var(--gc-color-medium-shade);
    --ion-color-medium-tint: var(--gc-color-medium-tint);
    
    /** light **/
    --ion-color-light: var(--gc-color-light);
    --ion-color-light-rgb: var(--gc-color-light-rgb);
    --ion-color-light-contrast: var(--gc-color-light-contrast);
    --ion-color-light-contrast-rgb: var(--gc-color-light-contrast-rgb);
    --ion-color-light-shade: var(--gc-color-light-shade);
    --ion-color-light-tint: var(--gc-color-light-tint);
}
