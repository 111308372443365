@import './variables.calendar'
:root 
    full-calendar.fc 
        &.fc-unthemed, &.fc-theme-standard  
            a
                color: var(--gc-text-color) 
            .fc-view
                background: var(--fc-page-bg-color)
            .fc-view-harness 
                td:not(.fc-resource-timeline-divider), th:not(.fc-resource-timeline-divider)
                    color: var(--gc-text-color) 
                    border-color: var(--fc-border-color)
                    overflow: hidden
                    text-wrap: nowrap
                    width: 100%
                th
                    background: var(--fc-page-bg-color)
                .fc-cell-shaded
                        background: var(--gc-border-high-contrast-color)
                        border: none
            .fc-daygrid-body-natural .fc-daygrid-day-events
                margin-bottom: 0 
                min-height: 48px
            .fc-timegrid-axis
                min-width: 100px
                width: 100px
            .fc-view-container 
                th 
                    background: var(--fc-page-bg-color) 
                    border: 1px solid var(--gc-border-color) 
                    color: var(--gc-text-color) 
                td 
                    &.fc-widget-content 
                        background: var(--gc-background-color) 
                        border: 1px solid var(--gc-border-color)
                        color: var(--gc-text-color) 
                    &.fc-head-container 
                        border: 1px solid var(--gc-border-color)
                .fc-row 
                    border-right: 1px solid var(--gc-border-color)
                .fc-event 
                    background: var(--gc-color-primary-shade)  
                    border: 1px solid var(--gc-color-primary-shade) 
                    color: var(--gc-color-primary-contrast) 
                .fc-divider 
                    background: #efefef 
                    border: 1px solid var(--gc-border-color)
            .fc-col-header
                    background: var(--gc-content-background)
                    &-cell-cushion
                        padding: 5px
            .fc-timegrid-slot-minor
                border-style: none
            .fc-daygrid-event-harness
                .fc-event
                    &-default
                        position: inherit
            .fc-event
                &-default
                    background: var(--fc-event-bg-color)
                    border: 2px solid var(--gc-text-color)
                    // border-left: 7px solid var(--fc-event-border-color, #3788d8)
                    // overflow: hidden //removed for tooltip
                    position: absolute
                    // TODO rework event templating
                    top: -1px
                    left: -1px
                    right: -1px
                    bottom: -1px
                    padding: 5px
                    min-height: 25px
                    display: flex
                    justify-content: center
                    font-size: 1rem
                    overflow: hidden
                &.fc-daygrid-dot-event:hover
                    background: var(--gc-content-background)
                    color: var(--gc-text-color) 
            .fc-v-event
                border-radius: 10px !important
                min-height: 25px
                .fc-event-main
                    color: var(--gc-text-color) !important
            .fc-toolbar 
                &.fc-header-toolbar
                    // display: none
                .fc-button 
                    color: var(--gc-color-primary-contrast) 
                    background: var(--gc-color-primary) 
                    border: 1px solid var(--gc-color-primary) 
                    font-size: 1rem 
                    transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s 
                    border-radius: 4px 
                    display: flex 
                    align-items: center 
                    &:enabled 
                        &:hover 
                            background: var(--gc-color-primary-shade) 
                            color: var(--gc-color-primary-contrast) 
                            border-color: var(--gc-color-primary-shade) 
                        &:active 
                            background: #0062cc 
                            color: #ffffff 
                            border-color: #0062cc 
                            &:focus 
                                outline: 0 none 
                                outline-offset: 0 
                                box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) 
                    .fc-icon-chevron-left 
                        font-family: "PrimeIcons" !important 
                        text-indent: 0 
                        font-size: 1rem 
                        &:before 
                            content: "\e900" 
                    .fc-icon-chevron-right 
                        font-family: "PrimeIcons" !important 
                        text-indent: 0 
                        font-size: 1rem 
                        &:before 
                            content: "\e901" 
                    &:focus 
                        outline: 0 none 
                        outline-offset: 0 
                        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) 
                .fc-button-group 
                    .fc-button 
                        border-radius: 0 
                        &:first-child 
                            border-top-left-radius: 4px 
                            border-bottom-left-radius: 4px 
                        &:last-child 
                            border-top-right-radius: 4px 
                            border-bottom-right-radius: 4px 
    .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button 
        background: var(--gc-color-secondary) 
        border: 1px solid var(--gc-color-secondary) 
        color: #ffffff 
        transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s 
    .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:hover 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-unthemed .fc-toolbar .fc-button.fc-dayGridMonth-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridWeek-button:focus, .fc.fc-unthemed .fc-toolbar .fc-button.fc-timeGridDay-button:focus 
        outline: 0 none 
        outline-offset: 0 
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) 
        z-index: 1 
    .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button 
        background: var(--gc-color-secondary) 
        border: 1px solid var(--gc-color-secondary) 
        color: #ffffff 
        transition: background-color 0.15s, border-color 0.15s, box-shadow 0.15s 
    .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:hover 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button.fc-button-active:hover, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button.fc-button-active:hover 
        background: var(--gc-color-secondary-shade) 
        border-color: var(--gc-color-secondary-shade) 
        color: #ffffff 
    .fc.fc-theme-standard .fc-toolbar .fc-button.fc-dayGridMonth-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridWeek-button:not(:disabled):focus, .fc.fc-theme-standard .fc-toolbar .fc-button.fc-timeGridDay-button:not(:disabled):focus 
        outline: 0 none 
        outline-offset: 0 
        box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5) 
        z-index: 1 

.day
  &.p-element
    display: flex
    flex-direction: column

.fc-v-event
  border-radius: 3px !important
  min-height: 25px
  .fc-event-main
    color: var(--gc-text-color) !important

.fc-resource-group
    background: var(--gc-border-color) !important