html:root, :root
	--fc-page-bg-color: var(--gc-background-color)
	--fc-neutral-bg-color: hsla(0,0%,82%,.3) 
	--fc-neutral-text-color: grey 
	--fc-border-color: rgba(var(--gc-border-high-contrast-color-rgb), 0.75)
	--fc-button-text-color: #fff 
	--fc-button-bg-color: #2c3e50 
	--fc-button-border-color: #2c3e50 
	--fc-button-hover-bg-color: #1e2b37 
	--fc-button-hover-border-color: #1a252f 
	--fc-button-active-bg-color: #1a252f 
	--fc-button-active-border-color: #151e27 
	--fc-event-bg-color: #fff 
	--fc-event-border-color: #3788d8 
	--fc-event-text-color: #fff 
	--fc-event-selected-overlay-color: rgba(0,0,0,.25) 
	--fc-more-link-bg-color: #d0d0d0 
	--fc-more-link-text-color: inherit 
	--fc-event-resizer-thickness: 8px 
	--fc-event-resizer-dot-total-width: 8px 
	--fc-event-resizer-dot-border-width: 1px 
	--fc-non-business-color: hsla(0,0%,84%,.3) 
	--fc-bg-event-color: #8fdf82 
	--fc-bg-event-opacity: 0.3 
	--fc-highlight-color: rgba(188,232,241,.3) 
	--fc-today-bg-color: rgba(255,220,40,.15) 
	--fc-now-indicator-color: red 
	--fc-gray: #495057
	--fc-weekend-highlight: #eee
	--fc-holiday-highlight: #E6E6E6

@media (prefers-color-scheme: dark)
	html:root, :root
		--fc-page-bg-color: var(--gc-background-color)	
		--fc-event-bg-color: #495057 
		--fc-weekend-highlight: #333
		--fc-holiday-highlight: #333

